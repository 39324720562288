import React, { Component, createRef } from 'react';
import { FaBars, FaDiceSix, FaHouse, FaCircleRight, FaCircleLeft, FaPeopleGroup, FaGear }  from 'react-icons/fa6'
import { IoPersonSharp } from 'react-icons/io5';
import AppContext from '../AppContext';
import Lobby from './Lobby';
import Join from './Join';
import Home from './Home';
import HowToPlay from './HowToPlay/HowToPlay';
import Profile from './Profile/Profile';
import logo from '../logo.png';
import Friends from './Friends/Friends';
import { FaQuestionCircle } from 'react-icons/fa';
import { BiFullscreen } from 'react-icons/bi';


class Navigation extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    // Determine initial orientation
    const isLandscape = window.innerWidth > window.innerHeight;

    this.state = {
      selectedMenuItem: 'Home', // Default selected menu item
      isNavbarVisible: window.innerWidth > 992, // State to manage navbar visibility on mobile
      isLandscape: isLandscape, // State to manage device orientation
    };

    this.navbarRef = createRef();
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Set initial state based on current window size
    document.addEventListener('mousedown', this.handleClickOutside);
    //checck the context for the gameId, if it exists, set the selectedMenuItem to Lobby
    if (this.context.gameId) {
      this.setState({ selectedMenuItem: 'Lobby' });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.navbarRef.current && !this.navbarRef.current.contains(event.target)) {
      this.setState({ isNavbarVisible: false });
    }
  };

  handleResize = () => {
    const isLandscape = window.innerWidth > window.innerHeight;
    this.setState({
       isNavbarVisible: window.innerWidth > 992,
       isLandscape: isLandscape,
       });
  };

  handleMenuItemClick = (menuItem) => {
  this.setState({ selectedMenuItem: menuItem, isNavbarVisible: window.innerWidth <= 992 ? false : this.state.isNavbarVisible /* Close navbar only on mobile*/});
    // You can add additional logic or callbacks here when a menu item is clicked
  };
  updateSelectedMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  toggleNavbar = () => {
    this.setState(prevState => ({
      isNavbarVisible: !prevState.isNavbarVisible
    }));
  };
  toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => {
        console.error(`Error attempting to enable fullscreen mode: ${e.message} (${e.name})`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((e) => {
          console.error(`Error attempting to disable fullscreen mode: ${e.message} (${e.name})`);
        });
      }
    }
  };

  renderContent = () => {
    const { selectedMenuItem, isLandscape } = this.state;

    // If the selected menu item is 'Lobby' and the device is not in landscape mode, show the rotation prompt
    if (selectedMenuItem === 'Lobby' && !isLandscape) {
      return (
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
          <FaDiceSix size={50} /> {/* Example icon, replace with any appropriate icon */}
          <p className='rotate-text'>Please rotate your device to landscape mode to access the Lobby.</p>
        </div>
      );
    }

    switch (selectedMenuItem) {
      case 'Home':
        return <Home updateSelectedMenuItem={this.updateSelectedMenuItem}/>;
      case 'Profile':
        return <Profile updateSelectedMenuItem={this.updateSelectedMenuItem}/>;
      case 'Lobby':
        return <Lobby updateSelectedMenuItem={this.updateSelectedMenuItem}/>; 
      case 'Join':
        return <Join updateSelectedMenuItem={this.updateSelectedMenuItem}/>;
      case 'HowToPlay':
        return <HowToPlay updateSelectedMenuItem={this.updateSelectedMenuItem}/>;
      case 'Friends':
        return <Friends updateSelectedMenuItem={this.updateSelectedMenuItem}/>;
      default:
        return null;  
    }
  };

  render() {
    const { username } = this.context;
    const { isNavbarVisible } = this.state;
    return (
      <div style = {{display:"flex"}}>
        <nav id="navbar" ref={this.navbarRef} className={isNavbarVisible ? 'navbar-visible' : ''}>
          <ul className="navbar-items flexbox-col">
            <li className="navbar-logo flexbox-left">
              <div className="navbar-item-inner flexbox">
              <img src={logo} className='app-logo-top' alt="logo" />
              </div>
              <span className="profile-name">{username}</span>
            </li>
            <li className={`navbar-item flexbox-left ${this.state.selectedMenuItem === 'Home' ? 'active' : ''}`} onClick={() => this.handleMenuItemClick('Home')}>
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaHouse/>
                </div>
                <span className="link-text">Home</span>
              </div>
            </li>
            <li className={`navbar-item flexbox-left ${this.state.selectedMenuItem === 'Profile' ? 'active' : ''}`} onClick={() => this.handleMenuItemClick('Profile')}>
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <IoPersonSharp/>
                </div>
                <span className="link-text">Profile</span>
              </div>
            </li>
            <li className={`navbar-item flexbox-left ${this.state.selectedMenuItem === 'HowToPlay' ? 'active' : ''}`} onClick={() => this.handleMenuItemClick('HowToPlay')}>
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaQuestionCircle/>
                </div>
                <span className="link-text">How To Play</span>
              </div>
            </li>
            <li className={`navbar-item flexbox-left ${this.state.selectedMenuItem === 'Lobby' ? 'active' : ''}`} onClick={() => this.handleMenuItemClick('Lobby')}>
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaDiceSix/>
                </div>
                <span className="link-text">Lobby</span>
              </div>
            </li>
            {/*<li className={`navbar-item flexbox-left ${this.state.selectedMenuItem === 'Join' ? 'active' : ''}`} onClick={() => this.handleMenuItemClick('Join')}>
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaCircleRight/>
                </div>
                <span className="link-text">Join</span>
              </div>
            </li>*/}
            <li className={`navbar-item flexbox-left ${this.state.selectedMenuItem === 'Friends' ? 'active' : ''}`} onClick={() => this.handleMenuItemClick('Friends')}>
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaPeopleGroup/>
                </div>
                <span className="link-text">Friends</span>
              </div>
            </li>
            <li className="navbar-item flexbox-left">
              <div className="navbar-item-inner flexbox-left">
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaGear/>
                </div>
                <span className="link-text">Settings</span>
              </div>
            </li>
            <li className="navbar-item logout flexbox-left">
              <div className="navbar-item-inner flexbox-left" onClick={this.context.logout}>
                <div className="navbar-item-inner-icon-wrapper flexbox">
                  <FaCircleLeft color = "#c2281d"/>
                </div>
                <span className="link-text">Logout</span>
              </div>
            </li>
          </ul>
        </nav>
        <main id="main" style = {{ flex: 1, minHeight:'100vh'}}>
          <div className="App-main">
            <div id="hamburger-menu" style= {{position:'absolute', top:'10px', left:'10px'}} onClick={this.toggleNavbar}>
              <FaBars style={{width: '8vmin', height: '8vmin'}}/>
            </div>
            <div id="btn-full-screen" style= {{position:'absolute', top:'calc(8vmin + 20px)', left:'10px'}} onClick={this.toggleFullscreen}>
              <BiFullscreen style= {{height: '8vmin', width:'8vmin'}}></BiFullscreen>
            </div>
            {this.renderContent()}
            <footer style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              padding: '5px 0',
              backgroundColor: 'transparent'
            }}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span style={{ color: 'white', fontSize: '12px', lineHeight: '1' }}>Like the game? </span>
                <a
                  href="https://www.buymeacoffee.com/gbaily"
                  target="_blank"
                  style={{ color: 'blue', fontSize: '12px', textDecoration: 'none', lineHeight: '1' }}
                >
                  Support the Project
                </a>
              </span>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span style={{ color: 'white', fontSize: '12px', lineHeight: '1' }}>Or join us on </span>
                <a
                  href="https://discord.gg/BN6GFaTjaV"
                  target="_blank"
                  style={{ color: 'blue', fontSize: '12px', textDecoration: 'none', lineHeight: '1' }}
                >
                  Discord
                </a>
              </span>
            </footer>
          </div>
        </main>
      </div>
    );
  }
}

export default Navigation;
