import React, { Component } from "react";
import axios from 'axios';
import AuthContext from '../AppContext';
import logo from '../logo.png';
import './LoginSignupBox.css';

const LegalModal = ({ title, content, onClose }) => (
    <div className="modal-overlay">
    <div className="modal-content">
        <h2>{title}</h2>
        <button className="modal-close" onClick={onClose}>X</button>
        <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div className="legal-content">
            {content}
        </div>
        </div>
        <button className="button btn-info" onClick={onClose}>Close</button>
    </div>
    </div>
);

class LoginSignupBox extends Component
{
    static contextType = AuthContext;
    constructor(props)
    {
        super(props);
        this.state = {
            boxState: "default",
            username: "",
            password: "",
            email: "",
            confirmEmail: "",
            errorText: "",
            confirmPassword: "",
        };
        // Binding method
        this.startSignUpClick = this.startSignUpClick.bind(this);
        this.startLoginClick = this.startLoginClick.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        this.apiUrl = process.env.REACT_APP_API_URL; 
    }
    componentDidMount() {
        // Load the Terms & Conditions text
        fetch('../terms.txt')
            .then(response => response.text())
            .then(text => this.setState({ termsText: text }))
            .catch(error => console.error('Error loading terms:', error));
        
        // Load the Privacy Policy text
        fetch('../privacy-policy.txt')
            .then(response => response.text())
            .then(text => this.setState({ privacyText: text }))
            .catch(error => console.error('Error loading privacy:', error));
    }
    
    componentDidUpdate(prevProps, prevState) {
        // Check if the component's state changed to either loggingIn or signingUp
        if ((this.state.boxState === "loggingIn" || this.state.boxState === "signingUp") && prevState.boxState !== this.state.boxState) {
            this.renderReCaptcha();
        }
    }
      
    renderReCaptcha = () => {
        if (window.grecaptcha && window.grecaptcha.render) {
            window.grecaptcha.render('recaptcha-container', {
            'sitekey' : '6Le0nnQpAAAAACcnr1f3QoO4oob4KraYlSNoK-vK'
            });
        } else {
            // Retry rendering the reCAPTCHA after a short delay
            setTimeout(this.renderReCaptcha, 100);
        }
    }

    handleLogin = async () => {
        try {
            if (!this.state.username || !this.state.password) {
                // add an error to a new div below the login form
                this.setState({errorText: "Please enter a username and password"});
                return;
            }

            const captchaResponse = window.grecaptcha.getResponse();
            if (captchaResponse.length === 0) {
                // reCAPTCHA not verified
                this.setState({ errorText: "Please verify you are not a robot." });
                return;
            }
          
            const response = await axios.post(`${this.apiUrl}/authenticate`, {
                username: this.state.username,
                password: this.state.password,
                captchaResponse
            });
            const token = response.data.token;
            const gameId = response.data.gameId;
            this.context.login(token, gameId);
        } catch (error) {
           this.setState({ errorText: "Username or password is incorrect." });
        }
    };

    handleSignup = async () => {
        try {
            if (!this.state.username || !this.state.password) {
                // add an error to a new div below the signup form
                this.setState({ errorText: 'Please enter a username and password' });
                return;
            }
            // ensure the users requested username contains no invalid characters
            if (this.state.username.includes(' ') || this.state.username.includes('@') || this.state.username.includes('.'))
            {
                this.setState({ errorText: 'Username cannot contain spaces, @, or .' });
                return;
            }
            // check if the confirm password matches
            if (this.state.password !== this.state.confirmPassword) {
                // add an error to a new div below the signup form
                this.setState({ errorText: 'Passwords do not match' });
                return;
            }
            // ensure the user enters an email
            if (!this.state.email) 
            {
                this.setState({ errorText: 'Please enter an email address' });
                return;
            }
            //ensure the email is valid
            if (!this.state.email.includes('@') || !this.state.email.includes('.'))
            {
                this.setState({ errorText: 'Please enter a valid email address' });
                return;
            }
            // check if the confirm email matches
            if (this.state.email !== this.state.confirmEmail) {
                // add an error to a new div below the signup form
                this.setState({ errorText: 'Emails do not match' });
                return;
            }
            // check that they have read the terms and conditions
            if (!this.state.agreedToTerms) {
                this.setState({ errorText: 'Please agree to the Terms & Conditions and Privacy Policy.' });
                return;
            }
            const captchaResponse = window.grecaptcha.getResponse();
            if (captchaResponse.length === 0) {
                // reCAPTCHA not verified
                this.setState({ errorText: "Please verify you are not a robot." });
                return;
            }

            const response = await axios.post(`${this.apiUrl}/signup`, {
                username: this.state.username,
                password: this.state.password,
                email: this.state.email,
                captchaResponse
            });
            const token = response.data.token;
            const gameId = response.data.gameId;
            this.context.login(token, gameId);
        } catch (error) {
            console.error('Signup failed:', error);
            //if code is 409, username already exists or email is already in use, otherwise, signup failed
            if (error.response.status === 409) {
                this.setState({ errorText: error.response.data.error });
            }
            else {
                this.setState({ errorText: 'Signup failed. Please try again.' });
            }
        }
    };

    // Toggle modal visibility
    toggleTerms = () => this.setState({ showTerms: !this.state.showTerms });
    togglePrivacy = () => this.setState({ showPrivacy: !this.state.showPrivacy });

    switchOnState(boxState)
    {
        const handleKeyPressSignup = (e) => {
            if (e.key === 'Enter') {
                this.handleSignup();
            }
        };
        const handleKeyPressLogin = (e) => {
            if (e.key === 'Enter') {
                this.handleLogin();
            }
        };

        switch(boxState)
        {
            default:
                return (
                    <div className="App-main">
                        <img src={logo} className='app-logo' alt="logo" />
                        <div className="feature-card login-form">
                            <button className="button btn-green" onClick={this.startSignUpClick}>Sign Up</button>
                            <div className="divider"></div>
                            <button className="button btn-info" onClick={this.startLoginClick}>Login</button>
                        </div>
                    </div>
                );
            case "signingUp":
                return(
                    <div className="App-main">
                        <div className="feature-card login-form">
                            <div className="form-group">
                                <span>Username</span>
                                <input className="form-field" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} type="text" placeholder="username"/>
                            </div>
                            <div className="form-group">
                                <span>Password</span>
                                <input className="form-field" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} type="password" placeholder="password"/>
                            </div>
                            <div className="form-group">
                                <span>Confirm</span>
                                <input className="form-field" value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} type="password" placeholder="password"/>
                            </div>
                            <div className="form-group">
                                <span>Email</span>
                                <input className="form-field" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} type="text" placeholder="email"/>
                            </div>
                            <div className="form-group">
                                <span>Confirm Email</span>
                                <input className="form-field" value={this.state.confirmEmail} onChange={(e) => this.setState({ confirmEmail: e.target.value })} onKeyDown={handleKeyPressSignup} type="text" placeholder="email"/>
                            </div>
                            <div className="form-group">
                                <div id="recaptcha-container"></div>
                            </div>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    id="agreeTerms"
                                    onChange={(e) => this.setState({ agreedToTerms: e.target.checked })}
                                />
                                <label htmlFor="agreeTerms" style={{ fontSize: '0.8em' }}>
                                    I agree to the{" "}
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleTerms();
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Terms & Conditions
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                        e.preventDefault();
                                        this.togglePrivacy();
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Privacy Policy
                                    </a>.
                                </label>
                            </div>
                            <div style={{margin: "0 0 3vmin 0" }}>{this.state.errorText}</div>
                            <button className="button btn-danger" onClick={this.cancelClick}>Cancel</button>
                            <div className="divider"></div>
                            <button className="button btn-green" onClick={this.handleSignup}>Sign Up</button>
                        </div>
                    </div>
                );
            case "loggingIn":
                return(
                    <div className="App-main">
                    <img src={logo} className='app-logo' alt="logo" />
                    <div className="feature-card login-form">
                        <div className="form-group">
                            <span>Username</span>
                            <input className="form-field" type="text" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} placeholder="username"/>
                        </div>
                        <div className="form-group">
                            <span>Password</span>
                            <input className="form-field" type="password"  value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyDown={handleKeyPressLogin} placeholder="password"/>
                        </div>
                        <div className="form-group">
                            <div id="recaptcha-container"></div>
                        </div>
                        <div style={{margin: "0 0 3vmin 0" }}>{this.state.errorText}</div>
                        <button className="button btn-danger" onClick={this.cancelClick}>Cancel</button>
                        <div className="divider"></div>
                        <button className="button btn-green" onClick={this.handleLogin}>Log In</button>
                    </div>
                    </div>     
                );
        }
    }
    render() {
        return (
            <>
                {this.switchOnState(this.state.boxState)}
                {this.state.showTerms && (
                    <LegalModal
                        title="Terms & Conditions"
                        content={this.state.termsText}
                        onClose={this.toggleTerms}
                    />
                )}
                {this.state.showPrivacy && (
                    <LegalModal
                        title="Privacy Policy"
                        content={this.state.privacyText}
                        onClose={this.togglePrivacy}
                    />
                )}
            </>
        );
    }
    
    startSignUpClick()
    {
        this.setState({boxState : "signingUp"});
    }
    startLoginClick()
    {
        this.setState({boxState : "loggingIn"});
    }
    cancelClick()
    {
        this.setState({boxState : "default", errorText: ""});
    }
}

export default LoginSignupBox;